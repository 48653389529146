import React from 'react';

const NotContraceptiveBorder = (props: React.PropsWithChildren<React.SVGProps<SVGSVGElement>>) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="70"
    height="70"
    viewBox="0 0 70 70"
  >
    <defs>
      <clipPath>
        <rect width="41.872" height="54.862" fill="currentColor" />
      </clipPath>
    </defs>
    <g transform="translate(-610 -1697)">
      <g transform="translate(-201 1426)">
        <g transform="translate(811 271)" fill="none" stroke="currentColor" strokeWidth="1">
          <rect width="70" height="70" stroke="none" />
          <rect x="0.5" y="0.5" width="69" height="69" fill="none" />
        </g>
      </g>
      <g transform="translate(627.068 1703.569)">
        <rect
          width="2.757"
          height="66.368"
          transform="matrix(0.802, -0.598, 0.598, 0.802, -2.006, 2.649)"
          fill="currentColor"
        />
        <g transform="translate(-0.005)">
          <g clipPath="url(#clip-path)">
            <path
              d="M5.642,46.3q0-8.783,0-17.565A4.208,4.208,0,0,1,8.07,24.75a4.347,4.347,0,0,1,2.032-.443q6.785.01,13.57,0,5.733,0,11.465,0a4.305,4.305,0,0,1,4.5,4.51q0,17.479,0,34.959a4.3,4.3,0,0,1-4.5,4.51q-12.5,0-24.992,0a4.3,4.3,0,0,1-4.5-4.51q0-8.74,0-17.48m1.109-.012q0,8.781,0,17.562a3.14,3.14,0,0,0,3.265,3.327q12.622.014,25.245,0A3.136,3.136,0,0,0,38.529,63.9q.008-17.6,0-35.21a3.129,3.129,0,0,0-3.242-3.262Q22.642,25.408,10,25.433a3.464,3.464,0,0,0-1.446.318,3.114,3.114,0,0,0-1.8,3.062q.007,8.738,0,17.476"
              transform="translate(-4.084 -17.605)"
              fill="currentColor"
            />
            <path
              d="M26.495,48.693a4.372,4.372,0,0,1-.013-8.744,4.372,4.372,0,1,1,.013,8.744m2.668-2.554a3.2,3.2,0,0,0-.4-4.159,3.161,3.161,0,0,0-4.074-.321l4.472,4.48M23.8,42.545a3.21,3.21,0,0,0,4.457,4.468L23.8,42.545"
              transform="translate(-16.024 -28.934)"
              fill="currentColor"
            />
            <path
              d="M81.625,44.331A4.37,4.37,0,1,1,86,48.693a4.37,4.37,0,0,1-4.373-4.362m2.588-2.7L88.7,46.107a3.225,3.225,0,0,0-4.482-4.476m3.517,5.424-4.48-4.48a3.238,3.238,0,0,0,4.48,4.48"
              transform="translate(-59.119 -28.935)"
              fill="currentColor"
            />
            <path
              d="M22.124,142.1a4.369,4.369,0,1,1,4.358,4.379,4.386,4.386,0,0,1-4.358-4.379m2.547-2.687,4.494,4.493a3.161,3.161,0,0,0-.282-4.016,3.229,3.229,0,0,0-4.213-.477m3.583,5.384-4.461-4.463a3.211,3.211,0,0,0,4.461,4.463"
              transform="translate(-16.024 -99.759)"
              fill="currentColor"
            />
            <path
              d="M22.124,93.231a4.369,4.369,0,1,1,8.738-.033,4.369,4.369,0,1,1-8.738.033m7.032,1.821a3.2,3.2,0,0,0-.641-4.387c-1.151-.925-3.057-.958-3.826-.085l4.468,4.472M23.81,91.408a3.215,3.215,0,0,0,.465,4.2,3.136,3.136,0,0,0,4.008.27L23.81,91.408"
              transform="translate(-16.024 -64.346)"
              fill="currentColor"
            />
            <path
              d="M81.625,142.121a4.365,4.365,0,1,1,8.73-.026,4.365,4.365,0,1,1-8.73.026m7.031,1.811a3.2,3.2,0,0,0-.467-4.233c-1.185-1.046-3.1-1.154-4.013-.235l4.48,4.468M83.3,140.3a3.218,3.218,0,0,0,.685,4.374,3.1,3.1,0,0,0,3.775.1L83.3,140.3"
              transform="translate(-59.119 -99.758)"
              fill="currentColor"
            />
            <path
              d="M81.626,93.167a4.365,4.365,0,1,1,4.3,4.435,4.35,4.35,0,0,1-4.3-4.435m7.032,1.875a3.193,3.193,0,0,0-.39-4.152,3.151,3.151,0,0,0-4.073-.328l4.462,4.48m-.934.92-4.47-4.47a3.215,3.215,0,0,0,.5,4.119,3.163,3.163,0,0,0,3.966.352"
              transform="translate(-59.12 -64.36)"
              fill="currentColor"
            />
            <path
              d="M66.413,59.4c0,.356.009.712,0,1.068s-.185.573-.553.567a.5.5,0,0,1-.523-.552q-.017-1.11,0-2.221a.51.51,0,0,1,.553-.569c.363.008.523.251.529.6.006.37,0,.741,0,1.111h0"
              transform="translate(-47.314 -41.785)"
              fill="currentColor"
            />
            <path
              d="M66.415,141.247c0,.372.008.744,0,1.116-.01.352-.187.579-.554.573a.5.5,0,0,1-.524-.557q-.014-1.116,0-2.233a.5.5,0,0,1,.529-.551c.369,0,.538.229.548.578s0,.715,0,1.073"
              transform="translate(-47.316 -101.107)"
              fill="currentColor"
            />
            <path
              d="M66.415,161.765c0,.358,0,.715,0,1.073s-.188.589-.543.588a.521.521,0,0,1-.54-.591c0-.73-.006-1.459,0-2.189a.5.5,0,0,1,.519-.56.508.508,0,0,1,.559.564c.011.372,0,.744,0,1.116"
              transform="translate(-47.316 -115.947)"
              fill="currentColor"
            />
            <path
              d="M66.412,79.827c0,.37,0,.741,0,1.111-.005.35-.18.586-.536.59a.515.515,0,0,1-.547-.578q-.011-1.089,0-2.178c0-.344.163-.582.534-.585s.536.226.546.573,0,.712,0,1.068h0"
              transform="translate(-47.311 -56.629)"
              fill="currentColor"
            />
            <path
              d="M66.415,100.32c0,.358,0,.715,0,1.073s-.169.591-.532.6a.512.512,0,0,1-.55-.576q-.01-1.094,0-2.189a.511.511,0,0,1,.551-.575c.365.006.526.248.531.6s0,.715,0,1.073"
              transform="translate(-47.315 -71.45)"
              fill="currentColor"
            />
            <path
              d="M66.413,120.776c0,.358.006.716,0,1.073s-.156.589-.529.6a.506.506,0,0,1-.552-.57c-.009-.73-.008-1.46,0-2.19a.517.517,0,0,1,.549-.581c.358,0,.53.245.534.6s0,.715,0,1.073"
              transform="translate(-47.313 -86.265)"
              fill="currentColor"
            />
            <path
              d="M66.412,38.9c0,.372.011.744,0,1.116a.537.537,0,1,1-1.073-.012c-.011-.744-.009-1.488,0-2.232a.509.509,0,0,1,.523-.562.517.517,0,0,1,.556.573c.009.372,0,.744,0,1.116h0"
              transform="translate(-47.316 -26.952)"
              fill="currentColor"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default NotContraceptiveBorder;
